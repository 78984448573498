// Facebook Converions API
import axios from 'axios'

export default ({ app }, inject) => {
  const fbApi = {
    // Check if user came from Meta campaign
    isFromMetaCampaign() {
      // Check cookie first
      const metaCampaignCookie = app.$cookies.get('hp.meta_campaign')
      if (metaCampaignCookie) return true

      // Check URL parameter
      if (process.client) {
        const urlParams = new URLSearchParams(window.location.search)
        const isFromMeta = urlParams.get('mtm_source') === 'meta'

        if (isFromMeta) {
          // Set cookie for 1 day
          app.$cookies.set('hp.meta_campaign', true, {
            maxAge: 60 * 60 * 24 * 1,
            path: '/',
          })
          return true
        }
      }

      return false
    },

    async sendEvent({
      eventName,
      eventSourceUrl,
      userEmail,
      userData = {},
      customData = {},
    }) {
      try {
        if (!eventName || !eventSourceUrl) {
          console.error('Missing required fields in the request body')
          return null
        }

        if (!process.env.FACEBOOK_EVENTS_URL) {
          console.error('Function is not configured properly')
          return null
        }

        const restrictedEvents = ['ContactMentor', 'DownloadJournal']
        if (
          restrictedEvents.includes(eventName) &&
          !this.isFromMetaCampaign()
        ) {
          // Skip restricted events if not from Meta campaign
          return null
        }

        // Hash email if provided
        if (userEmail) {
          userData = {
            ...userData,
            em: await hashValue(userEmail, 'email'),
          }
        }

        // Hash user data fields
        if (userData) {
          const { country, ph, fn, ln } = userData
          userData = {
            ...userData,
            ...(country && { country: await hashValue(country, 'country') }),
            ...(ph && { ph: await hashValue(ph, 'phone') }),
            ...(fn && { fn: await hashValue(fn, 'default') }),
            ...(ln && { ln: await hashValue(ln, 'default') }),
          }
        }

        const eventData = JSON.stringify({
          eventName,
          eventSourceUrl,
          userData: {
            client_user_agent: navigator.userAgent,
            ...userData,
          },
          customData,
        })

        const response = await axios.get(process.env.FACEBOOK_EVENTS_URL, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.FACEBOOK_EVENTS_API_KEY,
            'x-event-data': Buffer.from(eventData).toString('base64'),
          },
        })

        return response.data
      } catch (error) {
        console.error('Facebook Conversions API Error:', error)
        return null
      }
    },
  }

  // Hash function using built-in Web Crypto API
  async function hashValue(value, type = 'default') {
    if (!value) return null

    // Normalize the value
    const normalizedValue = normalizeValue(value, type)
    if (!normalizedValue) return null

    try {
      // Convert string to UTF-8 encoded array
      const encoder = new TextEncoder()
      const data = encoder.encode(normalizedValue)

      // Create SHA-256 hash using Web Crypto API
      const hashBuffer = await crypto.subtle.digest('SHA-256', data)

      // Convert to hex string
      return Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('')
    } catch (error) {
      console.error('Hashing error:', error)
      return null
    }
  }

  // Normalize function for Facebook data requirements
  function normalizeValue(value, type) {
    if (!value) return ''
    value = value.toString().trim()

    switch (type) {
      case 'email':
        return value.toLowerCase()
      case 'phone':
        // Remove non-digits and ensure country code
        return value.replace(/[^0-9]/g, '')
      case 'country':
        return value.toLowerCase().substring(0, 2)
      default:
        return value.toLowerCase()
    }
  }

  if (process.client) {
    fbApi.isFromMetaCampaign()
  }

  // Inject the Facebook API methods
  inject('fb', fbApi)
}
